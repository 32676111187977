<template>
  <div id="painel">
    <br>
    <section class="gradiente_vermelhos">
      <br>
      <v-container>
        <v-row  no-gutters>
          <v-col cols="12">
            <h1 class="_font_2_5rem" style="color: #eda53e"><b>Vantagens</b></h1>
            <pro-divisor></pro-divisor>
          </v-col>
        </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="6" lg="6" v-for="(item, index) in lista"  :key="'index3'+index">
                  <v-list style="background: transparent;padding-top: 0px; padding-bottom: 0px" dense >
                    <v-list-item style="text-align: left;" dark data-aos="fade-right">
                      <v-list-item-avatar size="20"  rounded>
                        <v-icon color="green" size="20" :data-aos="index%2===0 ? 'fade-up' : 'fade-up-left'">mdi-check</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <h3 class="_font_1_5rem" :data-aos="index%2===0 ? 'fade-up' : 'fade-up-left'"> {{item.titulo}}</h3>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-img :src="teste" height="300px" contain data-aos="fade-up"></v-img>
            </v-col>
        </v-row>
      </v-container>
      <br>
    </section>
    <br>
  </div>
</template>

<script async>
/* eslint-disable */
import teste from '../../assets/img/oficial/vantagen.svg'
  export default {
    name: 'SecaoPaineControle',
    data: () => ({
      teste,
      lista: [
        {titulo: 'Dispensa o uso de computadores'},
        {titulo: 'O cliente não tem que instalar nada em seu celular.'},
        {titulo: 'O valor é fixo independentemente do seu faturamento.'},
        {titulo: 'Pedidos chegam no WhatsApp'},
        {titulo: 'Pedidos Ilimitados'},
        {titulo: 'Agendamento de pedidos'},
        {titulo: 'Valor de frete fixo ou por bairro.'},
        {titulo: 'Sem Taxa e sem comissão.'},

      ]
    }),
    methods: {
    },
    mounted() {
    }
  }
</script>
